import { css } from '@emotion/react';
import { Paper } from '@mui/material';
import { memo } from 'react';
import { useLifecycles } from 'react-use';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fontWeight600 } from '~/css/font';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { fill_horizontal_all_center, flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { useQuoteUpdateColorCSS } from '~/modules/investment-consultant/updateQuoteAnimation/useQuoteUpdateColorCSS';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useStockToFutureMapper } from '~/modules/SDK/Symbol/useCommonSymbolList';
import useMedia from '~/hooks/useMedia';
const Dictionary = memo(function Dictionary() {
    const styleds = css `
    ${fill_horizontal_all_center};
    width: 14px;
    height: 14px;
    font-size: 10px;
    background-color: #23aa87;
    border-radius: 2px;
    color: #ffffff;
  `;
    return <div css={styleds}>期</div>;
});
const QuoteCell_ = memo(function QuoteCell_({ color, children, fontSize, }) {
    return (<div css={css `
        ${flex.h.allCenter};
        width: 100%;
        height: 40px;
        color: ${color};
        font-size: ${fontSize}px;
      `}>
      {children}
    </div>);
});
/** 默認 報價格式:商品,收盤,變動點數,變動%數 */
const DefaultSimpleQuoteBodyItem = memo(function DefaultSimpleQuoteBodyItem(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const changeColor = quoteChanges.closeChange >= 0 ? '#fb0000' : '#01c200';
    const changeArrow = quoteChanges.closeChange >= 0 ? '▲' : '▼';
    //TODO: 檢核海其商品漲跌幅
    const limitUp = symbolInfo?.limitUpPrice === symbolInfo?.close;
    const limitDn = symbolInfo?.limitDownPrice === symbolInfo?.close;
    const limitColor = limitUp ? '#fb0000' : limitDn ? '#01c200' : '';
    const isSelected = props.currentSymbol === props.symbol;
    const futuresDisctionary = useStockToFutureMapper();
    const updatingCSS = useQuoteUpdateColorCSS(symbolInfo?.close);
    const showStockFutures = props.showStockFutures ?? true;
    const theme = useThemeStore(s => s.theme);
    useLifecycles(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    }, () => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    const NameCss = css `
    ${flex.v.allCenter};
    width: 80%;
    height: 90%;
    background: ${limitColor};
    padding: 0 4px;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
    const darkBorderColor = isSelected ? '#eeeeee' : '#3c3c3c';
    const lighdarktBorderColor = isSelected ? '#888888' : '#ebebeb';
    const LimitAnimation = () => {
        if (limitUp) {
            return DefaultSimpleQuoteBodyItemClasses.LimitUpAnimation;
        }
        if (limitDn) {
            return DefaultSimpleQuoteBodyItemClasses.LimitDnAnimation;
        }
    };
    return (<Paper css={css `
        ${pureGrid};
        grid-template-columns: 5% 20% 25% 25% 25%;
        height: 40px;
        width: 100%;
        background: ${theme === 'dark' ? '#111111' : '#ffffff'}${isSelected ? 'ff' : 'aa'};
        cursor: pointer;
        font-size: 14px;
        ${fontWeight600};
        border: ${theme === 'dark' ? darkBorderColor : lighdarktBorderColor} 1px solid;
        margin: 1px 0px;
        transition: 0.3s;
        padding: 0px 2px;
        &:hover {
          background: ${theme === 'dark' ? '#222222' : '#eeeeee'}${isSelected ? 'ff' : 'aa'};
        }
      `} className={LimitAnimation()}>
      <QuoteCell_ color={changeColor}>&nbsp;{changeArrow}</QuoteCell_>

      <QuoteCell_>
        <div css={css `
            ${flex.h.allCenter};
            width: 25%;
            height: 50%;
            color: orange;
            font-size: 10px;
          `}>
          {showStockFutures && futuresDisctionary?.[props.symbol] ? <Dictionary /> : ''}
        </div>
        <div css={NameCss}>
          <div>
            <SymbolName symbol={props.symbol}/>
          </div>
          <div>{props.symbol}</div>
        </div>
      </QuoteCell_>

      <QuoteCell_ color={changeColor}>{symbolInfo?.close ?? '-'}</QuoteCell_>
      <QuoteCell_ color={changeColor}>
        {symbolInfo ? (quoteChanges.closeChange >= 0 ? '+' : '') : ''}
        {symbolInfo ? quoteChanges.closeChange : '-'}
      </QuoteCell_>
      <QuoteCell_ color={changeColor}>
        {symbolInfo ? quoteChanges.closeChangePercentString : '-'}
      </QuoteCell_>
    </Paper>);
});
/** 持有中 報價格式:商品,收盤,報酬率,日期 */
export const HoldingSimpleQuoteBodyItem = memo(function HoldingSimpleQuoteBodyItem(props) {
    const { isPc } = useMedia();
    const { currentSymbol, symbol, profit, equity, date, entryPrice } = props;
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const entryDateString = date?.substring(isPc ? 0 : 5, 10).replaceAll('-', '/');
    const profitpercentage = equity === 1
        ? ((symbolInfo?.close ?? 0) / entryPrice - 1) * 100
        : (1 - (symbolInfo?.close ?? 0) / entryPrice) * 100;
    const changeColor = quoteChanges.closeChange >= 0 ? '#fb0000' : '#01c200';
    const profitColor = profit >= 0 ? '#aa0000' : '#01aa00';
    const limitUp = symbolInfo?.limitUpPrice === symbolInfo?.close;
    const limitDn = symbolInfo?.limitDownPrice === symbolInfo?.close;
    const limitPercent = limitUp || limitDn;
    const limitColor = limitUp ? '#fb0000' : limitDn ? '#01c200' : '';
    const isSelected = currentSymbol === symbol;
    const futuresDisctionary = useStockToFutureMapper();
    const theme = useThemeStore(s => s.theme);
    const numFormatter = new Intl.NumberFormat('en');
    const showStockFutures = props.showStockFutures ?? true;
    useLifecycles(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    }, () => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    const darkBorderColor = isSelected ? '#eeeeee' : '#3c3c3c';
    const lighdarktBorderColor = isSelected ? '#888888' : '#ebebeb';
    return (<Paper css={css `
        ${pureGrid};
        grid-template-columns: 22% 19% 19% 18% 22%;
        height: 40px;
        width: 100%;
        background: ${theme === 'dark' ? '#141416' : '#fefefe'}${isSelected ? 'ff' : 'aa'};
        cursor: pointer;
        font-size: 14px;
        ${fontWeight600};
        border: ${theme === 'dark' ? darkBorderColor : lighdarktBorderColor} 1px solid;
        transition: 0.3s;
        padding: 0px 2px;
        &:hover {
          background: ${theme === 'dark' ? '#222222' : '#eeeeee'}${isSelected ? 'ff' : 'aa'};
        }
      `}>
      <QuoteCell_>
        <div css={css `
            ${flex.h.allCenter};
            width: 20%;
            height: 50%;
            color: orange;
            font-size: 10px;
          `}>
          {showStockFutures && futuresDisctionary?.[props.symbol] ? <Dictionary /> : ''}
        </div>
        <div css={css `
            ${flex.v.allCenter};
            width: 80%;
            height: 90%;
            background: ${limitColor};
            padding: 0 4px;
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}>
          <div>
            <SymbolName symbol={props.symbol}/>
          </div>
          <div>{props.symbol}</div>
        </div>
      </QuoteCell_>

      <QuoteCell_ color={changeColor}>{symbolInfo?.close ?? '-'}</QuoteCell_>

      <QuoteCell_ color={profitColor}>
        {profit > 0 ? '+' : ''}
        {numFormatter.format(profit)}
      </QuoteCell_>

      <QuoteCell_ color={profitColor}>
        {profit >= 0 ? '+' : ''}
        {profitpercentage.toFixed(2)}%
      </QuoteCell_>
      <QuoteCell_ fontSize={12}>{entryDateString}</QuoteCell_>
    </Paper>);
});
export const DefaultSimpleQuoteBodyItemClasses = {
    LimitUpAnimation: `${DefaultSimpleQuoteBodyItem.name}-LimitUpAnimation`,
    LimitDnAnimation: `${DefaultSimpleQuoteBodyItem.name}-LimitDnAnimation`,
};
export default { Default: DefaultSimpleQuoteBodyItem, Holding: HoldingSimpleQuoteBodyItem };
